import React from 'react'
import './styles/App.css'
import { EsimSvg, Lifestyle, LogoName, Wave } from './assets/svg'
import Houdini from './assets/image/Houdini_button.png'
import CoinGecko from './assets/image/Coingecko_button.png'
import Cmc from './assets/image/CMC_button.png'
import Dextools from './assets/image/Dextools_button.png'
import { motion } from "framer-motion"
import RoadmapMd from './assets/image/RoadmapMd.png'
import RoadmapMob from './assets/image/Roadmap_mobile.png'
import Video from './assets/Teaser.mp4'
import { Drawer, IconButton, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu';
import Example from './components/Chart'
import Pdf from './assets/Whitepaper.pdf'

import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';

import jsonPrepid from './assets/svg/Prepaid.json'
import jsonGaming from './assets/svg/Gaming.json'

import Google from './assets/image/Google.png'
import HoudiniImg from './assets/image/Houdini.png'
import IBM from './assets/image/IBM.png'
import Google1 from './assets/image/Google1.png'
import Google5 from './assets/image/Google5.png'
import Google6 from './assets/image/Google6.png'
import Vaultech from './assets/image/Vaultech1.png'
import Xception from './assets/image/Xception1.png'

import TelegramIcon from '@mui/icons-material/Telegram';
import XIcon from '@mui/icons-material/X';
import Marquee from 'react-fast-marquee'
import { Controls, Player } from '@lottiefiles/react-lottie-player'


function App() {
  const imgPartner = [Google, HoudiniImg, IBM, Google1, Google5, Google6, Vaultech, Xception]
  const menu = [
    { label: 'About', link: '#about' },
    { label: 'Tokenomics', link: '#tokenomics' },
    { label: 'Roadmap', link: '#roadmap' },
  ]
  const [openDrawer, setOpenDrawer] = React.useState(false)
  return (
    <div className='flex w-screen overflow-hidden flex-col items-center'>
      <Drawer
        anchor={'left'}
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <div className='w-[250px]'>
          <List>
            {menu.map((dt, index) => (
              <a href={dt.link} key={index} onClick={() => setOpenDrawer(false)}>
                <ListItem disablePadding>
                  <ListItemButton>
                    {/* <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon> */}
                    <ListItemText primary={dt.label} />
                  </ListItemButton>
                </ListItem>
              </a>
            ))}
            <a href={Pdf} download='whitepaper-decentracard.pdf' onClick={() => setOpenDrawer(false)}>
              <ListItem disablePadding>
                <ListItemButton>
                  {/* <ListItemIcon>
                    {index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
                  </ListItemIcon> */}
                  <ListItemText primary={'Whitepaper'} />
                </ListItemButton>
              </ListItem>
            </a>
          </List>
        </div>
      </Drawer>
      <div className='flex flex-col items-center w-full max-w-[1320px] justify-center'>
        <div className='w-[90%] py-4 sticky top-0 flex items-center justify-between'>
          <div className='flex items-center gap-2 md:gap-4'>
            <IconButton className='lg:!hidden' onClick={() => setOpenDrawer(true)}>
              <MenuIcon className='!text-white' />
            </IconButton>
            <LogoName className='w-[120px] md:w-[160px]' />
          </div>
          <div className='hidden lg:flex items-center text-white gap-14'>
            {menu.map((dt, i) => (
              <a key={i} href={dt.link} className='border border-transparent hover:border-b-white duration-200'>
                {dt.label}
              </a>
            ))}
            <a href={Pdf} download='whitepaper-decentracard.pdf' className='border border-transparent hover:border-b-white duration-200'>
              Whitepaper
            </a>
          </div>
          <div>
            <a href='https://store.decentracard.com/' className='w-fit'>
              <button className='--bg-launch text-white hover:scale-95 duration-150 text-xs flex items-center gap-2 md:text-base px-2 py-1 md:px-4 md:py-[6px]'>
                <ShoppingCartOutlinedIcon />
                <span className='font-bold'>Store</span>
              </button>
            </a>
          </div>
        </div>

        <div className='pt-12 w-full relative flex items-center justify-center'>
          <div className='absolute flex top-0 flex-col items-center'>
            <div className='bg-[#696969] --blur-effect h-[300px] rounded-full w-[100px]'></div>
            <div className='bg-[#fbda927b] --blur-effect h-[300px] rounded-full w-[600px]'></div>
          </div>
          <div className='flex flex-col relative z-20 text-white gap-6 items-center'>
            <div className='w-[80%] md:w-[50%]'>
              <video className='' loop autoPlay muted>
                <source src="./Video.webm" type="video/webm" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div className='w-full flex flex-col items-center'>
              <span className='text-[28px] md:text-[40px] lg:text-[54px] line-heigt leading-[50px] font-medium'><span className='--text-gold-gradient font-semibold'>Expect More</span> from Your</span>
              <span className='text-[28px] md:text-[40px] lg:text-[54px] --text-gold-gradient font-semibold'>Telegram Bot</span>
              <span className='text-xs mt-2 md:text-sm lg:text-base text-center'>Book a flight. Order an Uber. Dine out with family. <br />
                Get grocery for grandma. Do it all with <span className='--text-gold-gradient2 font-bold italic'>Decentracard.</span></span>
            </div>
            <a href='https://app.uniswap.org/swap?outputCurrency=0x2f3d0d2317802a65faac6e4cd94067c37b4d4804' className='w-fit'>
              <button className='--button-gold px-6 py-2 hover:scale-95 duration-150 font-bold rounded-lg text-base md:text-xl text-[#604C1C]'>
                BUY NOW
              </button>
            </a>
            <a href='https://t.me/DecentraCard_bot' className='--text-launch border-b'>Launch App</a>
            <motion.div
              initial={{ translateY: 100, opacity: 0 }}
              whileInView={{ translateY: 0, opacity: 1 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              className='flex items-center justify-center gap-6 mt-6 md:mt-10 lg:mt-16'>
              <img className='w-1/6 md:w-1/4' src={Cmc} alt='' />
              <img className='w-1/6 md:w-1/4' src={CoinGecko} alt='' />
              <img className='w-1/6 md:w-1/4' src={Houdini} alt='' />
              <img className='w-1/6 md:w-1/4' src={Dextools} alt='' />
            </motion.div>
            <motion.div
              initial={{ translateY: 100, opacity: 0 }}
              whileInView={{ translateY: 0, opacity: 1 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              className='flex w-[90%] lg:w-full flex-col --border-gold h-[340px] justify-center items-center gap-6 mt-[64px] lg:mt-[100px]'>
              <div className='hidden md:flex items-center gap-6'>
                <div className='--border-white md:h-[120px] md:w-[180px] lg:w-[250px] flex flex-col justify-center items-center'>
                  <span className='--text-gold-gradient2 md:text-sm lg:text-base font-medium'>Affiliator</span>
                  <span className='font-bold md:text-[24px] lg:text-[42px]'>122</span>
                </div>
                <div className='--border-white md:h-[120px] md:w-[180px] lg:w-[250px] flex flex-col justify-center items-center'>
                  <span className='--text-gold-gradient2 md:text-sm lg:text-base font-medium'>Total Users</span>
                  <span className='font-bold md:text-[24px] lg:text-[42px]'>2037</span>
                </div>
                <div className='--border-white md:h-[120px] md:w-[180px] lg:w-[250px] flex flex-col justify-center items-center'>
                  <span className='--text-gold-gradient2 md:text-sm lg:text-base font-medium'>Gift Card Sold</span>
                  <span className='font-bold md:text-[24px] lg:text-[42px]'>1788</span>
                </div>
              </div>
              <div className='hidden md:flex items-center gap-6'>
                <div className='--border-white md:h-[120px] md:w-[180px] lg:w-[250px] flex flex-col justify-center items-center'>
                  <span className='--text-gold-gradient2 md:text-sm lg:text-base font-medium'>Total Value (USD)</span>
                  <span className='font-bold md:text-[24px] lg:text-[42px]'>67697.58</span>
                </div>
                <div className='--border-white md:h-[120px] md:w-[180px] lg:w-[250px] flex flex-col justify-center items-center'>
                  <span className='--text-gold-gradient2 md:text-sm lg:text-base font-medium'>Week Revenue Share</span>
                  <span className='font-bold md:text-[24px] lg:text-[42px]'>1007</span>
                </div>
                <div className='--border-white md:h-[120px] md:w-[180px] lg:w-[250px] flex flex-col justify-center items-center'>
                  <span className='--text-gold-gradient2 md:text-sm lg:text-base font-medium'>Total Revenue Share</span>
                  <span className='font-bold md:text-[24px] lg:text-[42px]'>13508</span>
                </div>
              </div>

              <div className='flex md:hidden items-center gap-6 w-full justify-center'>
                <div className='--border-white h-[86px] md:h-[120px] w-2/5 md:w-[180px] lg:w-[250px] flex flex-col justify-center items-center'>
                  <span className='--text-gold-gradient2 text-xs md:text-sm lg:text-base font-medium'>Affiliator</span>
                  <span className='font-bold text-[20px] md:text-[24px] lg:text-[42px]'>122</span>
                </div>
                <div className='--border-white h-[86px] md:h-[120px] w-2/5 md:w-[180px] lg:w-[250px] flex flex-col justify-center items-center'>
                  <span className='--text-gold-gradient2 text-xs md:text-sm lg:text-base font-medium'>Total Users</span>
                  <span className='font-bold text-[20px] md:text-[24px] lg:text-[42px]'>2037</span>
                </div>
              </div>
              <div className='flex md:hidden items-center gap-6 w-full justify-center'>
                <div className='--border-white h-[86px] md:h-[120px] w-2/5 md:w-[180px] lg:w-[250px] flex flex-col justify-center items-center'>
                  <span className='--text-gold-gradient2 text-xs md:text-sm lg:text-base font-medium'>Gift Card Sold</span>
                  <span className='font-bold text-[20px] md:text-[24px] lg:text-[42px]'>1788</span>
                </div>
                <div className='--border-white h-[86px] md:h-[120px] w-2/5 md:w-[180px] lg:w-[250px] flex flex-col justify-center items-center'>
                  <span className='--text-gold-gradient2 text-xs md:text-sm lg:text-base font-medium'>Total Value (USD)</span>
                  <span className='font-bold text-[20px] md:text-[24px] lg:text-[42px]'>67697.58</span>
                </div>
              </div>
              <div className='flex md:hidden items-center gap-6 w-full justify-center'>
                <div className='--border-white h-[86px] md:h-[120px] w-2/5 md:w-[180px] lg:w-[250px] flex flex-col justify-center items-center'>
                  <span className='--text-gold-gradient2 text-xs md:text-sm w-[90%] text-center lg:text-base font-medium'>Week Revenue Share</span>
                  <span className='font-bold text-[20px] md:text-[24px] lg:text-[42px]'>1007</span>
                </div>
                <div className='--border-white h-[86px] md:h-[120px] w-2/5 md:w-[180px] lg:w-[250px] flex flex-col justify-center items-center'>
                  <span className='--text-gold-gradient2 text-xs md:text-sm w-[90%] text-center lg:text-base font-medium'>Total Revenue Share</span>
                  <span className='font-bold text-[20px] md:text-[24px] lg:text-[42px]'>13508</span>
                </div>
              </div>
            </motion.div>

          </div>
        </div>
      </div>

      <div className='flex w-[95%] lg:w-[70%] max-w-[1320px] items-center justify-center relative mt-[64px] lg:mt-[130px]'>
        <div className='w-full flex flex-col absolute items-center'>
          <div className='w-full flex justify-end'>
            <div className='bg-[#ffb803ad] h-[250px] --blur-effect w-[250px]'></div>
          </div>
          <div className='w-full flex justify-start'>
            <div className='bg-[#00D1FFad] h-[250px] --blur-effect w-[250px]'></div>
          </div>
        </div>
        <video
          controls autoPlay muted loop
          type="video/mp4"
          src={Video}
          className='h-[200px] md:h-[350px] lg:h-[500px] rounded-lg flex items-center justify-center' />
      </div>

      <div id='about' className='flex flex-col gap-5 w-[95%] lg:w-[70%] relative text-white max-w-[1320px] items-center justify-center mt-[15%] md:mt-[10%] lg:mt-[5%]'>
        <motion.div
          initial={{ translateY: 100, opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className='flex flex-col items-center gap-2'>
          <span className='text-[30px] leading-none font-normal'>About</span>
          <span className='--text-gold-gradient leading-none text-[40px] md:text-[50px] font-semibold'>Decentracard</span>
        </motion.div>

        <div className='w-full flex flex-col absolute bottom-[10%] lg:bottom-0 items-center'>
          <div className='w-full flex justify-center lg:justify-end'>
            <div className='bg-[#00d0ff54] h-[250px] --blur-effect w-[250px]'></div>
            <div className='bg-[#00D1FF54] h-[250px] --blur-effect w-[250px]'></div>
          </div>
        </div>

        <motion.div
          initial={{ translateY: 100, opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className='flex flex-col items-center w-[90%] md:mt-[30px] lg:mt-[60px]'>
          <div className='flex justify-start w-full'>
            <span className='text-base md:text-[20px] font-bold'>First Mover Tech with Telegram Bot + Revenue Share</span>
          </div>
          <div className='flex lg:flex-row flex-col justify-between mt-2 items-center'>
            <div className='flex flex-col items-center gap-4 lg:w-[50%] text-left'>
              <span className='text-xs md:text-[14px] font-normal'>Decentracard is a pioneer in the world of digital payments and  services. We're dedicated to providing secure, private, and convenient  solutions for your digital needs. With a focus on decentralization, we  empower you with control over your data and finances while simplifying  online shopping, global connectivity, gaming, and lifestyle services.</span>
              <span className='text-xs md:text-[14px] font-normal'>At Decentracard, we're not just innovating; we're leading the way  with first-mover technology. Our pioneering Telegram bot integration  makes accessing our services effortless, directly from your Telegram  environment. We're here to redefine convenience in the digital age.</span>
              <span className='text-xs md:text-[14px] font-normal'>Moreover, we're committed to sharing our success  with our community. Holders of Decentracard tokens ($DCARD) have the  opportunity to share in the revenue generated by our platform. It's our  way of showing appreciation for your support and involvement in the  Decentracard journey.</span>
            </div>
            <div className='flex w-[90%]  md:w-[70%] lg:mt-0 mt-6 lg:w-[50%] justify-center items-center'>
              {/* <img src={Card1} alt='' className='w-2/3' /> */}
              <video width="640" height="360" loop autoPlay muted>
                <source src="./Video.webm" type="video/webm" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
          <div className='w-full text-center mt-10 md:mt-[60px]'>
            <span className=' text-sm md:text-[20px] lg:text-[24px] text-[#00D1FF] font-bold'>Welcome to Decentracard - where the future is decentralized, and the benefits are shared with you.</span>
          </div>
        </motion.div>
      </div>

      <div id='category' className='flex flex-col gap-[64px] w-[95%] lg:w-[70%] relative text-white max-w-[1320px] items-center justify-center mt-[15%] md:mt-[10%] lg:mt-[190px]'>
        <motion.div
          initial={{ translateY: 100, opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className='--bg-category relative w-[90%] overflow-hidden p-6 lg:px-9 lg:h-[400px] flex md:flex-row gap-4 flex-col justify-between'>
          <Wave className='absolute right-0 bottom-0 z-[-1] w-full h-full rounded-[20px] bg-[#ffffff1f]' />
          <div className='flex flex-col order-2 md:order-1 items-start justify-center md:w-1/2 w-full gap-3 md:gap-5'>
            <span className='--text-gold-gradient text-[24px] lg:text-[32px]'>Prepaid Cards</span>
            <span className='text-xs'>Decentracard  brings you the future of payments with our Decentralized Prepaid Cards.  Choose between the Prepaid Mastercard and Prepaid Visa Card. Shop,  dine, travel securely with Decentracard, the pioneer in combining  convenience with decentralized security. Spend confidently wherever Mastercard or Visa is accepted, online or in-store. Plus, when you shop at our featured merchants, get 5% cashback for future spending.  Experience the Decentracard advantage today!</span>
            <a href='https://store.decentracard.com/category/prepaid%20card' className='w-fit'>
              <button className='--bg-launch md:px-4 py-1 px-3 md:py-2 text-sm hover:scale-95 duration-150'>Try Now</button>
            </a>
          </div>
          <div className='md:w-1/2 w-full flex order-1 md:order-2 items-center justify-center'>
            {/* <img src={PrepaidImg} className='w-[40%] md:w-auto md:h-[270px] select-none' alt='' /> */}
            {/* <PrepaidSvg/> */}
            <Player
              autoplay
              loop
              src={jsonPrepid}
              className='!w-[90%] md:!w-auto md:!h-[350px]'
            >
              <Controls visible={false} />
            </Player>
          </div>
        </motion.div>
        <motion.div
          initial={{ translateY: 100, opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className='--bg-category relative w-[90%] overflow-hidden p-6 lg:px-9 lg:h-[400px] flex md:flex-row gap-4 flex-col justify-between'>
          <Wave className='absolute right-0 bottom-0 z-[-1] w-full h-full rounded-[20px] bg-[#ffffff1f]' />
          <div className='md:w-1/2 w-full flex items-center justify-center'>
            {/* <img src={EsimSvg} className='w-[40%] md:w-auto h-[270px]' alt='' /> */}
            <EsimSvg className='!w-[90%] md:!w-auto md:!h-[350px]' />
          </div>
          <div className='flex flex-col items-start justify-center md:w-1/2 w-full gap-3 md:gap-5'>
            <span className='--text-gold-gradient text-[24px] lg:text-[32px]'>eSIMs</span>
            <span className='text-xs'>Stay connected the Decentracard way with our eSIMs - eSIM USA, eSIM Global, eSIM UK, and eSIM Australia. Enjoy instant, high-speed internet while  keeping your primary SIM. We're the first mover in eSIM technology,  providing a decentralized and secure solution for global connectivity.  Decentracard ensures you stay connected without compromising your  privacy.</span>
            <a href='https://store.decentracard.com/list-esim' className='w-fit'>
              <button className='--bg-launch md:px-4 py-1 px-3 md:py-2 text-sm hover:scale-95 duration-150'>Try Now</button>
            </a>
          </div>
        </motion.div>
        <motion.div
          initial={{ translateY: 100, opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className='--bg-category relative w-[90%] overflow-hidden p-6 lg:px-9 lg:h-[400px] flex md:flex-row gap-4 flex-col justify-between'>
          <Wave className='absolute right-0 bottom-0 z-[-1] w-full h-full rounded-[20px] bg-[#ffffff1f]' />
          <div className='flex flex-col order-2 md:order-1 items-start justify-center md:w-1/2 w-full gap-3 md:gap-5'>
            <span className='--text-gold-gradient text-[24px] lg:text-[32px]'>Gaming</span>
            <span className='text-xs'>Level  up your gaming experience with Decentracard. Discover our Gaming  category, including Steam Gift Cards, Google Play gift codes,  PlayStation Store, Xbox Gift Cards, PUBG Mobile UC, and PUBG New State  Gift Cards. We're not just about gaming; we're also the first mover in  decentralized and secure gaming purchases. Get your game on with  Decentracard!</span>
            <a href='https://store.decentracard.com/category/gaming' className='w-fit'>
              <button className='--bg-launch md:px-4 py-1 px-3 md:py-2 text-sm hover:scale-95 duration-150'>Try Now</button>
            </a>
          </div>
          <div className='md:w-1/2 w-full flex order-1 md:order-2 items-center justify-center'>
            {/* <img src={Gaming} className='w-[40%] md:w-auto h-[270px]' alt='' /> */}
            {/* <GamingSvg className='!w-[90%] md:!w-auto md:!h-[350px]' /> */}
            <Player
              autoplay
              loop
              src={jsonGaming}
              className='!w-[90%] md:!w-auto md:!h-[350px]'
            // style={{ height: '300px', width: '300px' }}
            >
              <Controls visible={false} />
            </Player>
          </div>
        </motion.div>
        <motion.div
          initial={{ translateY: 100, opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className='--bg-category relative w-[90%] overflow-hidden p-6 lg:px-9 lg:h-[400px] flex md:flex-row gap-4 flex-col justify-between'>
          <Wave className='absolute right-0 bottom-0 z-[-1] w-full h-full rounded-[20px] bg-[#ffffff1f]' />
          <div className='md:w-1/2 w-full flex items-center justify-center'>
            {/* <img src={Esim} className='w-[40%] md:w-auto h-[270px]' alt='' /> */}
            <Lifestyle className='!w-[80%] md:!w-auto md:!h-[220px]' />
          </div>
          <div className='flex flex-col items-start justify-center md:w-1/2 w-full gap-3 md:gap-5'>
            <span className='--text-gold-gradient text-[24px] lg:text-[32px]'>Lifestyle</span>
            <span className='text-xs'>Indulge in life's pleasures the Decentracard way. Explore Uber, DoorDash, Airbnb, Starbucks, NordVPN, NordPass, Skype, Hotels.com, Flightgift, Tinder Plus, and Tinder Gold. Enjoy the convenience of our decentralized and secure offerings. Whether it's transportation, dining, travel, or online security, Decentracard's Telegram bot puts you in control. Your lifestyle, your security, your choice - only with Decentracard!</span>
            <a href='https://store.decentracard.com/list-esim' className='w-fit'>
              <button className='--bg-launch md:px-4 py-1 px-3 md:py-2 text-sm hover:scale-95 duration-150'>Try Now</button>
            </a>
          </div>
        </motion.div>

      </div>

      <div className='w-full flex flex-col items-center gap-3 md:gap-4 mt-[15%] md:mt-[10%] lg:mt-[120px]'>
        <span className='text-white  text-[36px] md:text-[50px] font-semibold'>Partnership</span>
        <div id='Partnership' className=' w-full --bg-partnership py-4 md:py-8 flex flex-col items-center justify-center'>
          <motion.div
            initial={{ translateX: 100, opacity: 0 }}
            whileInView={{ translateX: 0, opacity: 1 }}
            transition={{ duration: 1 }}
            viewport={{ once: true }}
            className='w-[90%] lg:w-[80%] hidden md:flex items-center justify-evenly gap-6 max-w-[1320px] lg:h-[100px]'>
            {imgPartner.map(img => (
              <img src={img} alt='' className='w-fit max-h-[26px] md:max-h-[40px] lg:max-h-[50px]' key={img} />
            ))}
          </motion.div>
          <div className=' lg:w-[80%] flex md:hidden items-center flex-wrap justify-evenly gap-6 max-w-[1320px] lg:h-[100px]'>
            <Marquee >
              {imgPartner.map(img => (
                <img src={img} alt='' key={img} className='h-[40px] ml-4' />
              ))}
            </Marquee>
          </div>
        </div>
      </div>

      <div id='tokenomics' className='flex flex-col gap-5 w-[95%] lg:w-[70%] relative text-white max-w-[1320px] items-center justify-center mt-[64px] md:mt-[100px] lg:mt-[180px]'>
        <motion.div
          initial={{ translateY: 100, opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className='flex flex-col items-center gap-2'>
          <span className='leading-none text-[40px] md:text-[50px] font-semibold'>Tokenomics</span>
        </motion.div>
        <div className='flex flex-col items-center w-[90%]'>
          <div className='flex justify-start w-[95%] lg:w-[65%]'>
            <motion.div
              initial={{ translateY: 100, opacity: 0 }}
              whileInView={{ translateY: 0, opacity: 1 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              className='flex flex-col items-center gap-4 w-full text-justify'>
              <span className='w-full text-sm font-medium text-center'>Decentracard  has tokenomics and reward structures that are specifically designed to  encourage the project's organic growth and incentize holding the $DCARD  token. The tokens resides on the ERC-20 blockchain and paired with $ETH.</span>
            </motion.div>
          </div>
          <div className='w-full relative flex justify-end h-[300px] lg:h-[500px]'>
            <div className='w-full flex flex-col absolute top-[10%] lg:top-[20%] items-center'>
              <div className='w-full flex justify-center '>
                <div className='bg-[#00d0ff6a] h-[250px] --blur-effect w-[250px]'></div>
                <div className='bg-[#00D1FF54] h-[250px] --blur-effect w-[250px]'></div>
              </div>
            </div>
            <motion.div
              initial={{ translateY: 100, opacity: 0 }}
              whileInView={{ translateY: 0, opacity: 1 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              className='w-full h-full'
            >
              <Example />
            </motion.div>
          </div>
        </div>
      </div>

      <div id='roadmap' className='flex flex-col gap-5 w-[95%] lg:w-[70%] relative text-white max-w-[1320px] items-center justify-center mt-[64px] md:mt-[100px] lg:mt-[180px]'>
        <motion.div
          initial={{ translateY: 100, opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className='flex flex-col items-center gap-2'>
          <span className='leading-none text-[40px] md:text-[50px] font-semibold'>Roadmap</span>
        </motion.div>
        <motion.div
          initial={{ translateY: 100, opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className='flex flex-col items-center w-full mt-[30px] lg:mt-[60px] relative'>
          <div className='absolute w-full h-full'></div>
          <div className='w-full relative flex justify-center'>
            <div className='w-full flex flex-col absolute top-[10%] lg:top-[20%] items-center'>
              <div className='w-full flex justify-center '>
                <div className='bg-[#00d0ff6a] h-[250px] --blur-effect w-[250px]'></div>
                <div className='bg-[#00D1FF54] h-[250px] --blur-effect w-[250px]'></div>
              </div>
            </div>
            <img src={RoadmapMd} alt='' className='w-[95%] lg:w-full md:block hidden ' />
            <img src={RoadmapMob} alt='' className='w-[95%] md:hidden block' />
          </div>
        </motion.div>
      </div>

      <div id='category' className='flex flex-col gap-3 md:gap-6 w-[95%] lg:w-[80%] relative text-white max-w-[1320px] items-center justify-center mt-[15%] md:mt-[10%] lg:mt-[120px]'>
        <motion.div
          initial={{ translateY: 100, opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className='flex flex-col text-[20px] md:text-[30px] items-center'>
          <span className='font-medium'>Keep Transaction Private with</span>
          <span className='font-bold'>HOUDINI SWAP</span>
        </motion.div>
        <motion.span
          initial={{ translateY: 100, opacity: 0 }}
          whileInView={{ translateY: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          viewport={{ once: true }}
          className='text-xs md:text-base text-center'>Privately swap, send or bridge with magical ease. <br />
          With no trace between sending and receiving wallets,
          it's safe, compliant and always lowest cost.</motion.span>
        <div id="houdiniSwap" className='w-full md:h-[700px] h-[850px] lg:h-[600px]'>
          <iframe className="iframe"
            title='houdini'
            src="https://houdiniswap.com/?widgetMode=true&theme=dark&tokenIn=ETH&tokenOut=ETH&amount=1&anonymous=true&partnerId=65d94b61dd1eaea825b60a4f&accountId=tuDMRgPzbuXBnDc3VpjnuZ&tokenLockOut=false">
          </iframe>
        </div>
      </div>

      <div className='w-full --bg-footer justify-between flex flex-col items-center md:flex-row gap-8 flex-wrap md:items-start px-[5%] mt-[7%] py-[5%]'>
        <div className='flex flex-col gap-2 items-center md:items-start text-white'>
          <span className='text-[20px] font-bold'>Services</span>
          <a href='https://store.decentracard.com/category/prepaid%20card'>Prepaid Cards</a>
          <a href='https://store.decentracard.com/list-esim'>eSIMs</a>
          <a href='https://store.decentracard.com/category/gaming'>Gaming</a>
          <a href='https://store.decentracard.com/category/lifestyle'>Lifestyle</a>
        </div>
        <div className='flex flex-col gap-2 items-center md:items-start text-white'>
          <span className='text-[20px] font-bold'>Platform</span>
          <a href='https://t.me/DecentraCard_bot'>Telegram Bot</a>
          <a href='https://store.decentracard.com/'>Store</a>
        </div>
        <div className='flex flex-col gap-2 items-center md:items-start text-white'>
          <span className='text-[20px] font-bold'>Support</span>
          <a href='https://t.me/Decentracard'>Telegram</a>
          <a href='https://decentracard.gitbook.io/decentracard'>Documentation</a>
          <a href={Pdf} download='whitepaper-decentracard.pdf' target='blank' >Whitepaper</a>
        </div>
        <div className='flex flex-col gap-2 text-white w-full lg:w-[30%] items-center justify-start md:items-end lg:justify-end'>
          <LogoName className='w-[200px] md:w-[160px]' />
          {/* <img src={''} alt='' className='w-[20%] md:w-[10%]' /> */}
          <span>Managed by <span className='font-bold'>Decentracard</span></span>
          <div className='flex items-center gap-4'>
            <a href='https://t.me/Decentracard' className=' border border-transparent hover:border-white duration-150 h-8 w-8 rounded-full flex items-center justify-center'>
              <TelegramIcon />
            </a>
            <a href='https://twitter.com/DecentracardETH' className=' border border-transparent hover:border-white duration-150 h-8 w-8 rounded-full flex items-center justify-center'>
              <XIcon fontSize='small' />
            </a>
          </div>
          <span className='text-center'>© 2024 - Decentracard . All rights reserved.</span>
        </div>
      </div>

    </div>
  )
}

export default App