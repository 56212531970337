import React, { PureComponent } from 'react';
import { PieChart, Pie, Sector, ResponsiveContainer, Cell } from 'recharts';

const data = [
  { name: 'LP/Uniswap', value: 90 },
  { name: 'Listing & Market', value: 6 },
  { name: 'Onboarding', value: 4 },
];

const renderActiveShape = (props) => {
  const RADIAN = Math.PI / 180;
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 5) * cos;
  const sy = cy + (outerRadius + 5) * sin;
  const mx = cx + (outerRadius + 15) * cos;
  const my = cy + (outerRadius + 15) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';

  return (
    <g>
      <text x={cx} y={cy} dy={8} textLength={100} textAnchor="middle" height={300} className='w-[20px] text-white bg-slate-500' width={10} fill={fill} style={{ whiteSpace: 'nowrap' }}>
        <tspan x={cx} dy={8} textLength={payload.name === 'Listing & Market' ? 115 : 100} textAnchor="middle">
          {payload.name}
        </tspan>
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text x={ex + (cos >= 0 ? 1 : -1) * 7} y={ey} dy={-14} textAnchor={textAnchor} className='hidden lg:block' fill="#FFF">{payload.name}</text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 7} y={ey} dy={2} className='text-[10px] md:text-[13px]' textAnchor={textAnchor} fill="#999">
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

const COLORS = ['#D950E8', '#06f4fe', '#FFF'];

export default class Example extends PureComponent {
  static demoUrl = 'https://codesandbox.io/s/pie-chart-with-customized-active-shape-y93si';

  state = {
    activeIndex: 0,
    windowSize: window.innerWidth,
    size: 160,
    size2: 100,
  };

  onPieEnter = (_, index) => {
    this.setState({
      activeIndex: index,
    });
  };

  handleResize = () => {
    this.setState({
      windowSize: window.innerWidth
    }, () => {
      this.updateSize();
    });
  };

  updateSize = () => {
    const { windowSize } = this.state;

    let size;
    let size2;
    if (windowSize >= 1024) { 
      size = 160;
      size2 = 100;
    } else if (windowSize >= 600) { 
      size = 120;
      size2 = 75;
    } else { 
      size = 80;
      size2 = 63;
    }

    this.setState({
      size : size,
      size2 : size2
    });
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.updateSize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  render() {
    const { size, size2 } = this.state;
    return (
      <ResponsiveContainer>
        <PieChart width={10} height={60}>
          <Pie
            activeIndex={this.state.activeIndex}
            activeShape={renderActiveShape}
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={size2}
            outerRadius={size}
            fill="#8884d8"
            dataKey="value"
            onMouseEnter={this.onPieEnter}
          >
            {data.map((_, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>

    );
  }
}
